.industriesBanner {
    padding: 40px 0 40px;
    .industriesBanner_imgwrapper {
        top: 0px;
        width: 100%;
        z-index: -1
    }
    .industriesBanner_imgwrapper img {
        object-fit: cover;
        height: 100%;
        width: 100%
    }
    .form-wrap__flexbox {
        position: relative;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 .2rem 1rem 0 rgba(0, 0, 0, .11);
        padding: 3rem 3rem;
        margin: 0 auto;
    }
    .headingsec {
        text-align: left;
        margin: 0
    }
    .formbox-new .headingsec h2 span,
    .headingsec h2 {
        color: #181818;
        font-size: 24px;
        line-height: 24px;
        /* text-transform: uppercase; */
        font-weight: 700
    }
    .headingsec p {
        font-size: 16px;
        color: #737373
    }
    .development-discription p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 15px;
    }
}

@media (max-width: 1599px) {
    .industriesBanner {
        padding: 60px 0;
    }
    .header-text-blue {
        line-height: 40px
    }
}

@media (max-width: 1199.98px) {
    .industriesBanner {
        padding: 50px 0;
    }
    .header-text-blue {
        line-height: 37px
    }
}

@media (max-width: 991.98px) {
    .industriesBanner {
        padding: 30px 0 30px
    }
    .industriesBanner .btn {
        margin-top: 20px
    }
    .industriesBanner_btn {
        margin-top: 20px
    }
    .header-text-blue {
        line-height: 32px
    }
}

@media (max-width: 767.98px) {
    .industriesBanner {
        height: auto;
        padding: 20px 0 20px
    }
    .header-text-blue {
        line-height: 32px
    }
    @media (max-width: 575.98px) {
        .header-text-blue {
            line-height: 28px
        }
    }
}

.industriesBanner {
    .heading-block:after {
        content: '';
        display: block;
        margin-top: 10px;
        width: 60px;
        height: 3px;
        background: -webkit-gradient(linear, left top, right top, color-stop(50%, #50e590), color-stop(120%, #0796c6));
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
    }
    .header-text-blue {
        // line-height: 48px;
        color: #000;
        margin-bottom: 15px;
        font-size: calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        font-family: Raleway, sans-serif;
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .heading-block {
        margin-bottom: 30px;
    }
    .textbox p {
        margin: 3rem 0 0;
    }
    .textbox ul {
        margin: 1rem 0 0;
        display: block;
    }
    .textbox ul li {
        padding: 0 0 0 40px;
        margin: 0 0 1rem;
        position: relative;
        border: none;
        text-align: left;
        font-size: 20px;
        color: #4d5156;
        list-style: none;
    }
    .textbox ul li:before {
        content: '';
        position: absolute;
        border-color: #b0cb1f;
        border-style: solid;
        border-width: 0 4px 4px 0;
        top: 10px;
        left: 16px;
        transform: rotate(45deg);
        height: 15px;
        width: 7px;
    }
    .my_common_button-green {
        background: #b0cb1f;
        color: #fff;
        border: 0;
        margin-top: 20px;
        font-size: 18px;
        padding: 13px 20px;
        font-weight: 600;
        margin-right: 15px;
        text-align: center;
        text-decoration: none;
        text-shadow: none;
        /* text-transform: uppercase; */
        transition: all ease-in-out .5s;
        cursor: pointer;
    }
    .my_common_button-green:hover {
        background: #9fb812;
        opacity: 1;
        color: #ffffff;
    }
    .homepage-hero-customize {
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        color: #2878c9;
    }
    @media (max-width: 1440px) {
        .textbox ul li {
            font-size: 20px;
        }
    }
    @media (max-width: 1399px) {
        .textbox ul li {
            font-size: 18px;
        }
    }
    @media (max-width: 1199.98px) {
        .textbox ul li {
            font-size: 18px;
        }
    }
    @media (max-width: 991.98px) {
        .textbox ul li {
            font-size: 17px;
        }
    }
    @media (max-width: 767.98px) {
        .textbox ul li {
            font-size: 17px;
        }
    }
    @media (max-width: 575.98px) {
        .textbox ul li {
            font-size: 16px;
        }
    }
}

.trusted-logo {
    h6 {
        font-size: 1.125rem;
        margin: 0;
        font-weight: 700;
        color: #737373;
    }
    .left-portfolio::after {
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        height: 90px;
        width: 3px;
        background-color: #fff;
        margin: auto;
        bottom: 0;
    }
    @media (max-width: 991.98px) {
        h6 {
            text-align: center;
            font-size: 20px;
        }
        .left-portfolio::after {
            display: none;
        }
    }
}

// commonSection
.commonSection {
    // padding: 90px 0;
    .commonSection_header {
        margin-bottom: 50px;
    }
    .commonSection_title {
        font-family: Raleway;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .blue-border-line {
        height: 4px;
        width: 100px;
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
        display: block;
        margin: 0 0 auto;
        margin-bottom: 10px
    }
    .service-statistic__card {
        padding: 30px 25px 20px;
        margin-bottom: 30px;
        overflow: hidden;
        height: calc(100% - 25px);
        background-color: #fff;
        border-radius: 13px;
        box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
        transition: all ease-in-out .2s;
    }
    // .industries__item {
    //     position: relative;
    //     background-color: #fff;
    //     -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
    //     box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
    //     padding: 30px 25px 20px;
    //     height: 100%;
    //     width: 100%;
    //     border-radius: 8px;
    //     -webkit-box-sizing: border-box;
    //     box-sizing: border-box
    // }
}

.diverseIndustries {
    .industries__item {
        position: relative;
        background-color: #fff;
        box-shadow: 0 10px 30px 0 rgb(0 0 0 / 5%);
        padding: 30px 25px 20px;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
    }
}

.getStarted {
    .dashed-circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: inline-block;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 9%);
    }
    .circle-icon {
        vertical-align: top;
        padding: 40px 40px;
        top: -18px;
        margin: auto;
    }
    .line-arrow:after {
        position: absolute;
        content: "";
        height: 1px;
        top: 15%;
        right: -12.25%;
        width: 25%;
        border-bottom: 2px solid #c5ced5;
    }
    @media (max-width: 991.98px) {
        .line-arrow:after {
            display: none;
        }
    }
}

.standardSection {
    figcaption h3 {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2878c8;
        font-weight: 600;
    }
}

.verticals-section {
    article h3 {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2878c8;
        font-weight: 600;
    }
    article {
        background-color: #fff;
        transition: .3s;
        position: relative;
        padding: 40px 30px 0;
        margin-bottom: 30px;
        height: calc(100% - 10px);
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
    }
    article:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 60px solid #2879c9;
        border-right: 60px solid transparent;
        left: 0;
        top: 0;
        left: 0;
        transition: .3s ease-in-out
    }
    // article h3:after {
    //     content: "";
    //     height: 3px;
    //     width: 52px;
    //     background: #333;
    //     position: absolute;
    //     top: 20px;
    //     left: 0;
    //     opacity: .2
    // }
    article p {
        color: #333;
        font-size: 16px
    }
}

.frameworksWexperts {
    .circle-border {
        text-align: center;
        padding: 20px 20px;
        margin: auto;
        text-decoration: none;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 2px solid #d1d1d1;
        position: relative;
    }
}

.middle-content {
    .commonSection_header {
        margin-bottom: 50px;
    }
    .commonSection_title {
        font-family: Raleway;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
        font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .blue-border-line {
        height: 4px;
        width: 100px;
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
        display: block;
        margin: 0 0 auto;
        margin-bottom: 10px
    }
}

.commonSection_header {
    .content_box h3 {
        font-family: Raleway;
        font-weight: 600;
        color: #2878c9;
        margin-bottom: 15px;
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
    .empower_listing {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .empower_listing li {
        position: relative;
        padding-left: 30px;
        color: #4d5156;
        margin-bottom: 15px;
        font-size: 18px;
    }
    .empower_listing li:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #B0CB1F;
        left: 0;
        top: 7px;
    }
}

.featuresSection {
    .block-text h3 {
        font-family: Raleway;
        font-weight: 600;
        color: #2878c9;
        margin-bottom: 15px;
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
}

.simple-grid__heading {
    margin-bottom: 30px;
}

.simple-grid__heading .content_box-industries h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    // font-size: 18px;
    color: #2878c9;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 28px;
    margin-left: 25px;
}

.simple-grid__heading .content_box-industries h3:before {
    content: "";
    position: absolute;
    background: #B0C21F;
    height: 10px;
    width: 10px;
    left: 20px;
    border-radius: 50%;
    top: 7px;
}

.simple-grid__heading .content_box-industries p {
    font-size: 18px;
    color: #676666;
    margin-bottom: 15px;
    font-weight: 400;
    line-height: 28px;
    margin-left: 25px;
}

.middle__content__odd__even .development-bnr {
    margin: 0;
    padding: 170px 0;
}

.middle__content__odd__even .development-bnr.first {
    padding-top: 91px;
}

.middle__content__odd__even .development-bnr.odd {
    background: #f3f3f3;
}

.middle__content__odd__even img {
    /* box-shadow: 0 2px 38px 0 rgba(0, 0, 0, .15); */
    border-radius: 10px;
    width: 100%;
}

.middle__content__odd__even .development-bnr.odd .content__CTA {
    /* padding-right: 70px; */
    padding-left: 0;
}

@media (max-width: 991.98px) {
    .middle__content__odd__even .development-bnr {
        margin: 0;
        padding: 80px 0;
    }
}

@media (max-width: 767.98px) {
    .middle__content__odd__even .development-bnr.first {
        padding-top: 60px;
    }
    .middle__content__odd__even .development-bnr {
        margin: 0;
        padding: 60px 0;
    }
}

// step-section css start
.step-section {
    .menu_footer {
        width: 100%;
        position: relative
    }
    @media screen and (max-width: 991px) {
        /* .menu_footer {
            display: none
        } */
        .menu_footer .step_main_parent+.step_main_parent:before,
        .menu_footer .step_main_parent+.step_main_parent:after {
            display: none;
        }
        .service-single-card .service-img-blk img {
            width: 70px;
        }
        .service-single-card {
            margin: 2rem 0 1rem;
            display: block;
            text-align: center;
            min-height: 170px;
        }
    }
    .menu_footer h3 {
        margin: 0;
        font-size: 36px
    }
    .menu_footer h3.engage_steps_title {
        font-size: 30px;
        font-weight: 900;
        font-family: mont, sans-serif
    }
    .menu_footer h3 a {
        color: #000
    }
    .menu_footer h3 img {
        margin-left: 20px;
        opacity: 0;
        right: 15px;
        position: relative
    }
    .menu_footer h3 img,
    .menu_footer h3:hover img {
        transition: right .2s ease-out, opacity .1s ease-out
    }
    .menu_footer h3:hover img {
        right: 0;
        opacity: 1
    }
    .menu_footer .steps {
        background: 0 0;
        padding: 10px 10px 0;
        margin-top: 0
    }
    .menu_footer .number {
        position: relative;
        top: 10px;
        width: 76px;
        height: 76px;
        text-align: center;
        line-height: 76px;
        margin: 0 auto;
    }
    .menu_footer .number:after {
        content: "";
        position: absolute;
        top: 70%;
        left: 50%;
        width: calc(70% - 10px);
        height: calc(70% - 10px);
        background: #fff;
        z-index: 3;
        border-radius: 50px;
        -ms-box-shadow: 5px 0 20px rgba(0, 0, 0, .15);
        box-shadow: 5px 0 20px rgba(0, 0, 0, .15);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        z-index: 0
    }
    .menu_footer .number span {
        position: relative;
        z-index: 9;
        font-weight: 700;
        font-size: 28px;
        top: 14px
    }
    .menu_footer .step_main_parent {
        position: relative
    }
    .menu_footer .step_main_parent+.step_main_parent:before {
        content: "";
        position: absolute;
        left: 0;
        top: 70px;
        width: 50%;
        border-bottom: 1px dashed #2879c9;
        transform: translateX(-50%)
    }
    .menu_footer .step_main_parent+.step_main_parent:after {
        content: "";
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #2879c9;
        left: 0%;
        top: 62px;
        z-index: 99;
        position: absolute;
        transform: translateX(-50%);
        animation: none
    }
    .service-single-card {
        transition: all .3s ease-in-out;
        position: relative;
        box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
        border-radius: 6px;
        padding: 15px 15px 0;
        width: 100%;
        margin: 3rem 0 1rem;
        /* margin-bottom: 40px; */
        box-sizing: border-box;
        flex: 0 0 22.3%;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: #fff;
        min-height: 140px;
    }
    .service-single-card .service-img-blk img {
        width: 175px;
    }
    .service-single-card .service-content-blk h3 {
        font-size: 18px;
        color: #676666;
        font-weight: 600;
        line-height: 24px;
        margin-left: 10px;
    }
}

.faq-section {
    .accordion_head {
        background-color: #ffffff;
        color: #38424f;
        cursor: pointer;
        font-size: 20px;
        margin: 0 0 20px 0;
        padding: 30px 25px;
        font-weight: 600;
        display: block;
        position: relative;
        box-shadow: rgb(3 3 3 / 20%) 0px 0px 20px 0px;
    }
    .accordion_head .faqHead {
        display: block;
        font-family: 'Raleway', sans-serif;
    }
    .accordion_head h3.faqHead {
        font-weight: 700;
        font-size: 18px;
    }
    .accordion_head p {
        margin: 0px;
        color: #716c80;
        font-size: 16px;
        font-weight: normal;
    }
    .accordion_head li {
        padding: 10px 0 0 0;
        margin: 0px;
        color: #716c80;
        font-family: 'Raleway', sans-serif;
        font-size: 18px;
        font-weight: 400;
        /* margin-bottom: 10px; */
    }
    .panel-default>.panel-heading {
        color: #333;
        background-color: #fff;
        border-color: #e4e5e7;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .panel-default>.panel-heading a {
        display: block;
        padding: 10px 15px;
    }
    .panel-default>.panel-heading a:after {
        content: "";
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        float: right;
        transition: transform .25s linear;
        -webkit-transition: -webkit-transform .25s linear;
    }
    .panel-default>.panel-heading a[aria-expanded="true"] {
        background-color: #fff;
    }
    .panel-default>.panel-heading a[aria-expanded="true"]:after {
        content: "\2212";
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .panel-default>.panel-heading a[aria-expanded="false"]:after {
        content: "\002b";
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

// FAQ Section 
.accordion_container {
    width: 100%;
    margin-top: 20px;
}

.accordion_head {
    background-color: #ffffff;
    color: #38424f;
    cursor: pointer;
    font-size: 20px;
    margin: 0 0 20px 0;
    padding: 30px 25px;
    font-weight: 600;
    display: block;
    position: relative;
    box-shadow: rgba(3, 3, 3, 0.20) 0px 0px 20px 0px;
}

.accordion_body {
    background: #ffffff;
    padding: 0 20px;
    display: none;
}

.accordion_body p {
    padding: 20px 0 0 0;
    margin: 0px;
    color: #716c80;
    font-size: 18px;
    font-weight: normal;
}

// button commance css
.black-bg {
    background-color: #262525;
    padding: 80px 0;
}

.iq-font-white {
    font-size: 28px;
    font-weight: 700;
}

.btn--sm {
    /* font-family: Poppins-Medium;sd */
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    font-weight: 600;
    background-color: transparent;
    border: 2px solid #32c274;
    // -webkit-transition: all ease .3s;
    // -o-transition: all ease .3s;
    transition: all ease .3s;
    position: relative;
    overflow: hidden;
    z-index: 9;
}

.btn-shutter-more {
    display: inline-block;
    min-width: 170px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    transition: all .5s ease;
    padding: 0 20px;
    color: #2879c9;
    border: 2px solid #2879c9;
    line-height: 38px;
    background: 0 0;
}

.btn-shutter-more i {
    /* font-size: 18px; */
    font-weight: 600;
    margin-left: 12px;
    color: #2879c9;
}

.btn-shutter-more:hover {
    background: #2879c9;
    cursor: pointer;
    color: #fff;
    border-color: #2979c9;
}

.btn-shutter-more i :hover {
    background: #2879c9;
    cursor: pointer;
    color: #fff;
    border-color: #2979c9;
}

@media (min-width:768px) {
    .btn--sm {
        font-size: 16px;
        line-height: 30px
    }
}

@media (min-width:992px) {
    .our_services_innr {
        padding-bottom: 40px
    }
    .btn {
        padding: 10px 40px;
    }
}

@media (min-width:1025px) {
    .btn--sm {
        font-size: 18px;
        line-height: 32px
    }
}

.btn--sm:hover {
    color: #fff;
    border: 2px solid #fff;
    -webkit-text-fill-color: #fff
}

.btn--sm:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #32c272;
    background: -webkit-linear-gradient(8deg, rgba(16, 184, 87, .86) 0, rgba(16, 184, 87, .86) 1%, rgba(23, 183, 223, .86) 100%);
    background: -o-linear-gradient(8deg, rgba(16, 184, 87, .86) 0, rgba(16, 184, 87, .86) 1%, rgba(23, 183, 223, .86) 100%);
    background: linear-gradient(82deg, rgba(16, 184, 87, .86) 0, rgba(16, 184, 87, .86) 1%, rgba(23, 183, 223, .86) 100%);
    width: 100%;
    height: 0;
    z-index: -1;
    // -webkit-transition: all ease .3s;
    // -o-transition: all ease .3s;
    transition: all ease .3s
}

.btn--sm:hover:before {
    background: #32c272;
    background: linear-gradient(82deg, rgba(16, 184, 87, .86) 0, rgba(16, 184, 87, .86) 1%, rgba(23, 183, 223, .86) 100%);
    height: 100%
}

.btn--sm--white:before,
.btn--sm--white:hover:before {
    background: #fff
}

.btn--sm--white:hover {
    border: 2px solid transparent;
    color: #10b857
}

@media (max-width: 767.98px) {
    .iq-font-white {
        font-size: 20px;
        font-weight: 700;
    }
    .black-bg {
        background-color: #262525;
        padding: 40px 0;
    }
}

.major-industries {
    .textbox h3 {
        font-family: Raleway;
        font-weight: 600;
        color: #2878c9;
        margin-bottom: 15px;
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
}

.casestudiesSection {
    .casestudiesCard {
        padding: 30px 25px 20px;
        margin-bottom: 30px;
        overflow: hidden;
        height: calc(100% - 25px);
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
        transition: all ease-in-out .2s;
    }
    .omb_rgt_sec h3 {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2878c8;
        font-weight: 600;
    }
    .omb_list_icon {
        float: left;
        transition: all .3s linear;
        width: 25%;
        margin-top: 60px;
    }
    .omb_rgt_sec {
        float: right;
        width: 70%;
    }
    @media (max-width: 1199.98px) {
        .omb_list_icon {
            float: none;
            text-align: center;
            width: 50%;
            margin: 0 auto 0;
        }
        .omb_rgt_sec {
            float: none;
            width: auto;
            margin-top: 15px;
            text-align: center;
        }
    }
}

.integrationsSection {
    .integration-title {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2878c8;
        font-weight: 600;
    }
    .boxed {
        -webkit-transition: all ease-in .3s;
        -o-transition: all ease-in .3s;
        transition: all ease-in .3s;
    }
    // .integration-title {
    //     font-size: 24px;
    //     color: #2979c9;
    //     margin-bottom: 20px;
    //     font-weight: 700;
    //     line-height: 28px;
    // }
    .boxed {
        margin-bottom: 1.875rem;
        position: relative;
        position: relative;
        padding: 15px 25px 15px;
        overflow: hidden;
        height: calc(100% - 25px);
        background-color: #fff;
        /* border-radius: 13px; */
        -webkit-box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
        box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
        -webkit-transition: all ease-in-out .2s;
        transition: all ease-in-out .2s;
        z-index: 9;
    }
    /*  .boxed .circle img {
        height: auto;
        width: 100%;
        z-index: 9;
        position: relative;
    } */
    .boxed .circle img {
        /* max-width: 100% ;
        height: auto; */
        aspect-ratio: 16 / 9;
        max-width: 100%;
        object-fit: contain;
        z-index: 999;
    }
    .boxed:after {
        position: absolute;
        content: "";
        right: 0;
        width: 7.125rem;
        top: 0;
        bottom: 0;
        background: url(../images/industires/techno-icon/robo-sol-icon1bg.png) no-repeat right top;
    }
}

.services-text .content_box h3,
.omb_rgt_sec h3,
.industrySection_cnt h3,
.fusion-text h3,
.proccess-discrioption h3,
.flexibleSec__box h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    line-height: 28px;
    color: #2878c8;
    font-weight: 600;
}

.industries__item h3,
.service__heading--smaller h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    line-height: 28px;
    color: #2878c8;
    font-weight: 600;
}

// whychoose-sec css
.whychoose-sec .icon-1 {
    background-position: 28px 20px
}

.whychoose-sec .circle-area {
    width: 90px;
    height: 90px;
    border: 1px solid #f6f6f6;
    border-radius: 100%;
    /* box-shadow: .2px 1px 17.1px 12.9px rgba(0, 0, 0, .05); */
    float: left;
    margin: 30px 20px 0 20px
}

.whychoose-sec .circle-area {
    background: url(../images/industires/healthcare/why-choose-sprite3.png) left no-repeat;
    width: 90px;
    height: 90px;
    border: 1px solid #f6f6f6;
    border-radius: 100%;
    float: left;
    margin: 30px 20px 0 20px
}

.whychoose-sec {
    // padding: 70px 0;
    position: relative;
    background-color: #f6f6f6
}

.whychoose-sec .heading-sec p:after {
    background: #fff
}

.whychoose-sec ul {
    list-style: none
}

.whychoose-sec .partner {
    width: 100%;
    margin: 0 0 20px;
    overflow: hidden;
    display: block;
    min-height: 140px
}

.whychoose-sec .textsec {
    width: 75%;
    float: left
}

.whychoose-sec p {
    font-size: 18px;
    line-height: 1.5
}

.whychoose-sec .iconR {
    width: 117px;
    height: 117px;
    border: 2px solid #d1d1d1;
    border-radius: 100%;
    float: right;
    margin: 0 0 0 20px
}

.whychoose-sec .textsecR {
    width: 60%;
    float: right;
    text-align: right
}

.textsec h3 {
    font-size: 20px;
    color: #2879c9;
    padding: 20px 0 10px;
    text-align: left;
    font-weight: 700
}

.whychoose-sec .icon-1 {
    background-position: 5px 10px
}

.whychoose-sec .icon-2 {
    background-position: 5px -130px
}

.whychoose-sec .icon-3 {
    background-position: 5px -265px
}

.whychoose-sec .icon-4 {
    background-position: 5px -400px
}

.whychoose-sec .icon-5 {
    background-position: 5px -535px
}

.whychoose-sec .icon-6 {
    background-position: 5px -675px
}

// technologies and Framework
.stacks-gutter {
    padding: 80px 0;
}

.stacks-gutter .heading-gutter {
    text-align: center
}

.stacks-gutter .stacks-data ul {
    overflow: hidden;
    position: relative;
    border: solid 1px #dadfe3;
}

@media (min-width:768px) {
    .stacks-gutter .stacks-data ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center
    }
}

@media (min-width:768px) {
    .stacks-gutter .stacks-data ul:not(:last-child) {
        margin-bottom: 30px;
    }
}

@media (max-width:767px) {
    .stacks-gutter .stacks-data ul:not(:last-child) {
        margin-bottom: 18px
    }
    .content_box h3 {
        margin-top: 20px;
    }
    .textsec {
        width: 100%;
    }
}

@media (min-width:768px) {}

.stacks-gutter .stacks-data ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center
}

.stacks-gutter .stacks-data ul li.name {
    height: 70px;
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
    background: #eee;
    text-align: center;
}

.stacks-gutter .stacks-data ul li.name span {
    /* height: 70px; */
    font-size: 16px;
    text-transform: capitalize;
    color: #000;
    font-weight: 700;
    background: #eee;
    text-align: center;
}

figure {
    margin: 0;
}

@media (min-width:768px) {
    .stacks-gutter .stacks-data ul li.name {
        width: 100px;
        min-width: 120px;
        padding: 10px;
        height: 120px;
        text-align: center;
    }
    ul.tech-last-section {
        margin-left: 125px;
    }
}

@media (max-width:767px) {
    .stacks-gutter .stacks-data ul li.name {
        height: 35px;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center
    }
}

@media (max-width:767px) {
    .stacks-gutter .stacks-data ul li:not(.name) {
        float: left;
        width: 33.333%;
        min-height: 90px;
        display: inline-flex;
        border: 1px solid #dedede;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center
    }
}

@media (min-width:1024px) {
    .stacks-gutter .stacks-data ul li:not(.name) figure {
        padding: 12px 30px
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .stacks-gutter .stacks-data ul li:not(.name) figure {
        padding: 12px 2px 12px 32px
    }
}

@media (max-width:767px) {
    .stacks-gutter .stacks-data ul li:not(.name) figure {
        padding: 12px 20px
    }
    .stacks-gutter {
        padding: 60px 0;
    }
    .home-tech-sec {
        padding: 60px 0;
    }
}

@media (max-width:767px) {
    .col-xs-6:nth-child(odd) {
        padding-right: 7.5px
    }
    .col-xs-6:nth-child(even) {
        padding-left: 7.5px
    }
    .button-box1 {
        margin-top: 20px;
        text-align: center
    }
    .heading-block h1 {
        text-align: left;
    }
    .whychoose-sec .textsec {
        width: 100%;
    }
    .simple-grid__heading .content_box p {
        text-align: center;
    }
    .timeline h4 {
        font-size: 18px;
    }
    .flex-horizontal {
        display: block;
        margin-top: 1rem !important;
    }
    .timeline-sec {
        padding: 60px 0;
    }
    .timeline>li>.timeline-panel {
        width: 100%;
        height: calc(100% - 25px);
        max-height: 300px;
    }
    .timeline>li {
        width: 100%;
        padding: 0;
    }
    .timeline>li.timeline-inverted>.timeline-panel:after {
        display: none;
    }
    .timeline>li.timeline-inverted>.timeline-panel:before {
        display: none;
    }
    .simple-grid__heading .content_box h3 {
        font-size: 18px;
        text-align: left;
    }
    .simple-grid__heading {
        margin-bottom: 0 !important;
    }
    .timeline>li>.timeline-panel {
        width: 100%;
        height: calc(100% - 25px);
        max-height: 300px;
    }
}

@media (max-width:575px) {
    .col-xs-4,
    .col-xs-6 {
        width: 50%;
    }
}

@media all and (min-width:241px) and (max-width:480px) {
    .heading-block h1 {
        font-size: 20px;
        text-align: left;
    }
    .whychoose-sec .textsec {
        width: 100%;
    }
}

.empower_listing {
    margin-top: 20px;
    margin-bottom: 40px;
}

.empower_listing li {
    position: relative;
    padding-left: 30px;
    color: #4d5156;
    margin-bottom: 15px;
    font-size: 16px;
}

.empower_listing li:after {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #b0cb1f;
    left: 0;
    top: 7px;
}

.flexibleSec__box p {
    font-size: 16px;
    line-height: 27px
}

.flexibleSec__box {
    padding: 40px 28px 25px;
    height: 100%
}

.rounded-10 {
    border-radius: 10px
}

.shadow {
    box-shadow: 0 0 20px rgba(195, 190, 190, .4) !important
}

@media (max-width:767px) {
    .col-xs-6:nth-child(odd) {
        padding-right: 7.5px
    }
    .col-xs-6:nth-child(even) {
        padding-left: 7.5px
    }
    .button-box1 {
        margin-top: 20px;
        text-align: center
    }
    .heading-block h1 {
        text-align: left;
    }
}

@media (max-width:575px) {
    .col-xs-4,
    .col-xs-6 {
        width: 50% !important;
    }
}

.timeline-sec {
    .timeline>li {
        margin-bottom: 40px;
        position: relative;
        list-style: none;
    }
    .timeline>li:before,
    .timeline>li:after {
        content: " ";
        display: table
    }
    .timeline>li:after {
        clear: both
    }
    .timeline>li:before,
    .timeline>li:after {
        content: " ";
        display: table
    }
    .timeline>li:after {
        clear: both
    }
    .timeline-heading {
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .timeline h3 {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2879c9;
        font-weight: 700;
    }
    .timeline>li>.timeline-panel {
        width: 90%;
        /* width: 90%; */
        float: left;
        background-color: #ffffff;
        border: 1px solid #d4d4d4;
        border-radius: 2px;
        padding: 20px;
        position: relative;
        -webkit-box-shadow: 0 1px 6px rgb(0 0 0 / 18%);
        box-shadow: 0 1px 6px rgb(0 0 0 / 18%);
        max-height: 140px;
        height: 100vh;
        min-height: 180px;
    }
    .timeline>li:not(.timeline-inverted) {
        padding-right: 90px
    }
    .timeline>li.timeline-inverted {
        padding-left: 60px
    }
    .timeline>li>.timeline-panel:before {
        position: absolute;
        top: 0;
        right: -15px;
        display: inline-block;
        border-top: 91px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 89px solid transparent;
        content: " "
    }
    .timeline>li>.timeline-panel:after {
        position: absolute;
        top: 0px;
        right: -14px;
        display: inline-block;
        border-top: 90px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 88px solid transparent;
        content: " ";
    }
    .timeline>li>.timeline-badge {
        color: #B0CB1F;
        background-color: #ffffff;
        width: 90px;
        border: 4px solid #2879c9;
        height: 90px;
        line-height: 80px;
        font-size: 42px;
        text-align: center;
        position: absolute;
        top: 45px;
        left: 3%;
        margin-left: -25px;
        z-index: 100;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        float: right
    }
    .timeline>li.timeline-inverted>.timeline-panel:before {
        border-left-width: 0;
        border-right-width: 160px;
        left: -160px;
        right: auto
    }
    .timeline>li.timeline-inverted>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 160px;
        left: -160px;
        right: auto;
    }
    .timeline-title {
        margin-top: 0;
        color: inherit
    }
    .timeline-body>p,
    .timeline-body>ul {
        margin-bottom: 0
    }
    .timeline-body>p+p {
        margin-top: 5px
    }
    @media (max-width:767px) {
        .timeline>li.timeline-inverted {
            padding-left: 0px;
        }
        .timeline>li>.timeline-panel {
            width: 100%;
            height: calc(100% - 25px);
            max-height: 300px;
        }
        .timeline>li {
            width: 100%;
            padding: 0;
        }
        .timeline>li.timeline-inverted>.timeline-panel:after {
            display: none;
        }
        .timeline>li.timeline-inverted>.timeline-panel:before {
            display: none;
        }
        .timeline>li>.timeline-panel {
            width: 100%;
            height: calc(100% - 25px);
            max-height: 300px;
        }
        .timeline-badge,
        .timeline>li>.timeline-panel:after,
        .timeline>li>.timeline-panel:before {
            display: none
        }
    }
}

.commitment-gurantee {
    .card-panel-box {
        transition: all .3s ease-in-out;
        background: #fff;
        height: 100%;
        position: relative;
        background: #f9f9f9;
        box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
        border-radius: 6px;
        padding: 30px 25px 0;
        width: 100%;
        margin-bottom: 40px;
        box-sizing: border-box;
    }
    .great-fit-sprite-icon {
        width: 45%;
        margin: 0 auto;
        text-align: center;
    }
    .industries__item {
        position: relative;
        background-color: #fff;
        box-shadow: 0 10px 30px 0 rgb(0 0 0 / 5%);
        padding: 30px 25px 20px;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
    }
}